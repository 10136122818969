import {AuthModel, UserModel} from "./_models";
import * as authHelper from "./AuthHelpers";
import {setAllMerchants, setAuth, setLoading, setMessage} from "./reducer";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../redux/hooks/hook";
import {
    reqExchangeAccessToken,
    reqExchangeRefreshToken,
    reqGetAllMerchants,
    reqGetUserByAccessToken,
    reqLogout
} from "./_requests";
import {AxiosResponse} from "axios";
import pkceChallenge from "pkce-challenge";

const useAuth = () => {
    const dispatch = useDispatch();
    const {auth, allMerchants, loading, message} = useAppSelector(state => state.auth);
    const currentUser = auth.user;

    const setCurrentUser = (value: UserModel | undefined) => dispatch(setAuth({user: value}));

    const saveAuth = (auth: AuthModel | undefined) => {
        if (auth) {
            authHelper.setAuth(auth);
            dispatch(setAuth({accessToken: auth.accessToken}));
        } else {
            authHelper.removeAuth();
            dispatch(setAuth({accessToken: undefined}));
        }
    }

    const logout = (payload: string) => {
        saveAuth(undefined);
        setCurrentUser(undefined);
        dispatch(setAllMerchants([]));
        return reqLogout(payload).then((res: AxiosResponse) => {
            console.log(res);
        }).catch((err: AxiosResponse) => {
            console.log(err);
        });
    }

    const getUserByAccessToken = () => {
        return reqGetUserByAccessToken().then((res: any) => {
            authHelper.setCurrentUser(res);
            setCurrentUser(res);
        }).catch((err: any) => {
            console.log(err);
            if (auth?.accessToken) {
                logout(auth?.accessToken);
            }
            throw err;
        });
    }

    function getAllMerchants() {
        reqGetAllMerchants().then((res: any) => {
            dispatch(setAllMerchants(res.data.data));
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const exchangeAccessToken = async (code: string) => {
        dispatch(setLoading(true));
        try {
            const res = await reqExchangeAccessToken(code);
            saveAuth(res.data.data);
            dispatch(setLoading(false));
        } catch (err: any) {
            console.log(err);
            dispatch(setLoading(false));
            dispatch(setMessage(err?.response?.data?.error?.replaceAll("_", " ")));
        }
    };

    const exchangeRefreshToken = async (refresh_token: string) => {
        return await reqExchangeRefreshToken(refresh_token).then((res: any) => {
            saveAuth(res.data.data);
            console.log(res.data.data);
        }).catch((err: any) => console.log(err));
    }

    const generatePKCE = async () => {
        const {code_challenge, code_verifier} = await pkceChallenge();

        return {code_challenge, code_verifier};
    }

    const redirectToProduct = (name: string) => {
        generatePKCE().then(({code_challenge, code_verifier}) => {
            const appName = name.split(" ").at(-1)?.toLowerCase();
            // openNewTab(authRedirectUrl.toProduct + `/${appName}?app=${appName}`);
            const obtainBaseUrl = process.env.REACT_APP_OBTAIN_BASE_URL;
            const clientId = process.env.REACT_APP_PKCE_CLIENT_ID;
            const codeChallengeMethod = process.env.REACT_APP_CODE_CHALLENGE_METHOD;
            const redirectUri = `${process.env.REACT_APP_PRODUCT_URL}/auth/verify`;
            const scope = "openid profile";
            const responseType = "code";
            const state = {appId: appName, direction: "to-product", code_verifier, client_id: clientId};

            window.location.href = `${obtainBaseUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&state=${encodeURIComponent(JSON.stringify(state))}&code_challenge=${code_challenge}&code_challenge_method=${codeChallengeMethod}&code_verifier=${code_verifier}`;
        })
    }

    return {
        message,
        loading,
        logout,
        saveAuth,
        setCurrentUser,
        auth,
        currentUser,
        getUserByAccessToken,
        getAllMerchants,
        allMerchants,
        exchangeAccessToken,
        exchangeRefreshToken,
        redirectToProduct,
        generatePKCE
    }
}

export {useAuth}