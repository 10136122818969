import React, {useState} from 'react';
import {svgUrl} from "../../AssetHelpers";

type Props = {
    className?: string
    src: any;
    blank?: any;
    onClick?: any;
}

const KLSVGIMG: React.FC<Props> = ({className = "", src, blank = svgUrl("blank-image.svg"), onClick}) => {
    const [errorSrc, setErrorScr] = useState<any>();
    const srcError = (!errorSrc?.isTrusted && src) ? src : blank;

    const handleClick = (e: any) => onClick && onClick(e);

    return (
        <img
            onError={(err) => setErrorScr(err)}
            src={srcError}
            alt="Item"
            className={className}
            onClick={!errorSrc?.isTrusted ? handleClick : undefined}
        />
    )
}

export {KLSVGIMG};