import React, {FC, ReactNode} from "react";
import {Image} from "react-bootstrap";
import {svgUrl} from "../../../../../../../../../_kiloit/helpers";
import CustomCarousel from "../../../../../../../widgets/CustomCarousel";
import MobilePhone from "./mobilecontainer/MobilePhone";
import {useBusiness} from "../../../../../../core/action";
import {app} from "../../../../../../../../constants/appFeature";

interface Props {
    isFavourite: boolean;
    banners: string[];
    children: ReactNode;
    isMorePhotos?: boolean;
}

const LeftSideMobile: FC<Props> = ({isFavourite, banners, children, isMorePhotos = false}) => {
    const {business: {appId}} = useBusiness();
    const validApp = appId && [1, 2, 5, 6].includes(appId) ? appId : 1;
    const defaultBanners = Array(3).fill(0).map((_) => svgUrl(`${app[validApp].name.toLowerCase()}-default-banner.svg`));
    const allBanners = banners.length > 0 ? banners : defaultBanners;

    return <MobilePhone>
        <div>
            <div className="w-100 px-3 z-1 position-absolute d-flex justify-content-between align-items-center"
                 style={{marginTop: 35}}>
                <div><Image src={svgUrl("back.svg")} alt="arrow back"/></div>
                {isFavourite && <div className="d-flex align-items-center gap-2">
                    <Image src={svgUrl("heart.svg")}/>
                    <Image src={svgUrl("share.svg")}/>
                </div>}
            </div>
            <div className="w-100 d-flex justify-content-center position-relative">
                <CustomCarousel className="mobile"
                                indicators={true}
                                items={allBanners.map((banner, index) => {
                                    return {
                                        id: index,
                                        src: banner
                                    }
                                })}/>
                {isMorePhotos && <span className="position-absolute end-0 bottom-0 z-1 bg-black bg-opacity-50 text-white text-center pt-1 ps-1 fs-11 more-photos-mobile">More photos</span>}
            </div>
            {children}
        </div>
    </MobilePhone>
}

export default LeftSideMobile