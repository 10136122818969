import axios from "axios";
import {getAuth, getCurrentUser, removeAuth} from "../../app/modules/auth";
import {alertError} from "./alert/alertFunction";
import {authRedirectUrl} from "../../app/constants/redirect";

const authApi = axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_URL
});
const instanceApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const configInterceptor = (axios: any) => {
    axios.interceptors.request.use((config: any) => {
        const user = getCurrentUser();
        let merchantId = user?.id;
        config.headers = {
            ...config.headers,
            "Authorization": `Bearer ${getAuth()?.accessToken}`,
        }

        if (merchantId) {
            config.headers["X-Merchant-Id"] = merchantId;
        }
        return config;
    }, (err: any) => {
        return Promise.reject(err);
    });

    axios.interceptors.response.use((res: any) => {
        return res;
    }, (err: any) => {
        const status = err?.response?.status;

        if (status === 401  || err?.response?.data?.error?.code === "AUTHORIZATION_FILTER") {
            alertError({
                html: `${err?.response?.data?.message}<br/>Login again`,
                preConfirm: async () => {
                    removeAuth();
                    window.location.href = `${authRedirectUrl.signIn}?redir=${window.location.origin}`;
                }
            })
        }
        return Promise.reject(err);
    });
}

const setUpAxios = () => {
    configInterceptor(instanceApi);
}

export {authApi, instanceApi, setUpAxios}