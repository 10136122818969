import Swal from "sweetalert2";
import failed from "../../assets/svg/close.svg";
import {PropsAlert} from "./alert";
import close from "../../assets/svg/icons-for-button/btn-close.svg";

const swalOptions = {
    showCloseButton: true,
    closeButtonHtml: `<img src="${close}" alt="close" />`,
    backdrop: `rgba(0, 0, 0, 0.4)`,
    customClass: {
        closeButton: "custom-swal2-close btn-close-bg rounded-circle",
        confirmButton: "btn-fs light-btn fw-bold custom-btn-swal2 border px-5",
        popup: "custom-popup-swal custom-popup-swal-success",
        title: "fw-bold",
    },
};

const alertError = ({title = "Failed", text, preConfirm, html}: PropsAlert) => {
    Swal.fire({
        title: title,
        text: text,
        html: html,
        iconHtml: `<img src="${failed}" alt="error" />` ,
        confirmButtonText: 'OK',
        ...swalOptions,
        customClass: {
            ...swalOptions.customClass,
            title: "failed-color"
        },
        preConfirm: preConfirm
    }).then(() => {
        preConfirm && preConfirm()
    })
}

export {alertError}