export const kiloFoodBusiness = {
    left: {
        categories: [
            "Popular",
            "Ice Beverage",
            "Rice",
            "Noodle Soup"
        ],
        order: {
            title: "Most ordered"
        }
    },
    right: {}
}

export const kiloStayBusiness = {
    left: {
        amenities: {
            title: "Amenities",
            categories: [
                {
                    icon: "ac.svg",
                    title: "AC"
                },
                {
                    icon: "parking.svg",
                    title: "Parking"
                },
                {
                    icon: "restaurant.svg",
                    title: "Restaurant"
                },
                {
                    icon: "update.svg",
                    title: "24-hour Front Desk"
                },
                {
                    icon: "wifi.svg",
                    title: "Wifi"
                },
                {
                    icon: "elevator.svg",
                    title: "Elevator"
                }
            ]
        },
        policies: {
            title: "Accommodation Policies",
            description: "Minimum age of guests is: 18 years. Extra beds are dependents on the room you choose. Please check the individual room  capacity for more detail."
        },
        descriptions: {
            title: "Hotel Description",
            description: "Discover the vibrant neighborhood of Sangkat Sen Sok in Phnom Penh when you stay at HY Chanhan’s Hotel. Ideal for two travelers, this moder..."
        },
        price: {
            price: "USD 30",
            description: ["Price (room/night) starts from", "Inclusive of taxes"]
        }
    },
    right: {}
}

export const kiloAttractionBusiness = {
    left: {
        categories: ["In Town", "Beach", "Cave", "Temple", "Island"],
        tours: [
            {
                image: "tour1.svg",
                title: "Phnom Penh Tour",
                date: "5 days. Group Tour",
                description: "Royal Palace, Wat Phnom, Independence Monument..",
                price: "$ 100"
            },
            {
                image: "tour2.svg",
                title: "Phnom Penh & Siem Reap Tour",
                date: "5 days. Group Tour",
                description: "Royal Palace, Wat Phnom, Angkorwat, Bayon...",
                price: "$ 100"
            }
        ]
    },
    right: {
        title: "Phnom Penh Tour",
        description: "5 days. Group Tour",
        categories: ["Ticket", "Itinerary", "Tour Info"],
        travelDate: {
            title: "Travel Date",
            subTitle: "Free cancellation available",
            description: "Up to 24hours before the start date"
        },
        languageOption: {
            title: "Language Option"
        },
        ticket: {
            title: "How Many Tickets?",
            subTitle: "Adults (age 13-99)",
            price: "$100"
        }
    }
}

