import React, {FC, ReactNode} from "react";
import MobilePhone from "./mobilecontainer/MobilePhone";
import {Image} from "react-bootstrap";
import {svgUrl} from "../../../../../../../../../_kiloit/helpers";

interface Props {
    children: ReactNode;
    title: string;
}

const RightSideMobile: FC<Props> = ({children, title}) => {
    return <MobilePhone>
        <div style={{height: '100vh'}} className="pt-5">
            <div className="border-bottom ps-3 pb-2 d-flex align-items-center">
                <Image className="me-2" src={svgUrl("arrow_back_ios.svg")} alt="arrow_back"/>
                <h5 className="text-black fs-16 mb-0">{title}</h5>
            </div>
            {children}
        </div>
    </MobilePhone>
}

export default RightSideMobile