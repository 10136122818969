import React, {Fragment} from "react";
import {useBusiness} from "../../../../../core/action";
import {app} from "../../../../../../../constants/appFeature";

const MainMobile = () => {
    const {business: {appId}} = useBusiness();
    const validApp = appId ?? 1;

    return (
        <Fragment>
            <div
                className="mb-3 d-flex flex-column flex-md-row  flex-wrap gap-4 align-items-center justify-content-center justify-content-lg-end p-0">
                <div className="flex-shrink-0">
                    {app[validApp].mobileContentLeft}
                </div>
                <div className="flex-shrink-0">
                    {app[validApp].mobileContentRight}
                </div>
            </div>
        </Fragment>
    )
};

export default MainMobile;
