import React, {Fragment} from 'react'
import {Card, Col, Image} from 'react-bootstrap'
import {svgUrl} from 'src/_kiloit/helpers'

const ProductCard = () => {
    return (
        <Fragment>
            <Col xs={6}>
                <Card className="position-relative bg-light">
                    <Card.Body className="position-relative p-5">
                        <Image className='position-absolute end-0 bottom-0 ' src={svgUrl("add_circle_outline.svg")}
                               alt='addimage'/>
                    </Card.Body>
                </Card>
            </Col>
        </Fragment>
    )
}

export default ProductCard