import done from '../../assets/svg/done.svg'
import close from '../../../_kiloit/assets/svg/icons-for-button/btn-close.svg'
import failed from '../../../_kiloit/assets/svg/close.svg'
import Swal from "sweetalert2";

type PropsConfirm = {
    titleConfirm?: string,
    titleSuccess?: string,
    titleError?: string,
    textConfirm?: string,
    htmlConfirm?: string | undefined,
    textSuccess?: string,
    textError?: string,
    preConfirm?: any,
    afterConfirm?: () => void,
    onCancel?: () => void,
    onSuccess?: () => void
    onError?: () => void,
    afterClose?: () => void,
    cancelButtonText?: string,
    confirmButtonText?: string
}

export interface PropsAlert extends PropsConfirm {
    title?: string,
    text?: string,
    html?: string,
}

export interface PropsAlertDelete {
    upText: string,
    downText: string
}

const useSweetAlert = () => {
    const swalOptions = {
        showCloseButton: true,
        closeButtonHtml: `<img src="${close}" alt="close" />`,
        backdrop: `rgba(0, 0, 0, 0.4)`,
        customClass: {
            closeButton: "custom-swal2-close btn-close-bg rounded-circle",
            confirmButton: "btn-fs light-btn fw-bold custom-btn-swal2 border px-5",
            popup: "custom-popup-swal custom-popup-swal-success",
            title: "fw-bold",
        },
    };

    const alertSuccess = ({title = "Success", text, preConfirm, html}: PropsAlert) => {
        Swal.fire({
            title: title,
            text: text,
            html: html,
            iconHtml: `<img src="${done}" alt="success" />`,
            confirmButtonText: 'OK',
            ...swalOptions,
            preConfirm: preConfirm
        }).then(() => {
            preConfirm && preConfirm()
        })
    }

    const alertError = ({title = "Failed", text, preConfirm, html}: PropsAlert) => {
        Swal.fire({
            title: title,
            text: text,
            html: html,
            iconHtml: `<img src="${failed}" alt="error" />`,
            confirmButtonText: 'OK',
            ...swalOptions,
            customClass: {
                ...swalOptions.customClass,
                title: "failed-color"
            },
            preConfirm: preConfirm
        }).then(() => {
            preConfirm && preConfirm()
        })
    }

    const alertConfirm = ({
                              htmlConfirm,
                              textConfirm,
                              preConfirm,
                              onCancel,
                              titleConfirm = "Confirm",
                              cancelButtonText = "Cancel",
                              confirmButtonText = "Open"
                          }: PropsConfirm) => {
        Swal.fire({
            title: titleConfirm,
            html: htmlConfirm,
            text: textConfirm,
            showCancelButton: true,
            cancelButtonText: cancelButtonText,
            confirmButtonText: confirmButtonText,
            ...swalOptions,
            customClass: {
                ...swalOptions.customClass,
                confirmButton: "btn-fs border dark-btn custom-btn-swal px-5",
                cancelButton: "btn-fs border light-btn custom-btn-swal px-5",
                popup: "custom-popup-swal custom-popup-swal-confirm"
            },
            reverseButtons: true,
            preConfirm: () => {
                preConfirm && preConfirm();
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then(() => {
            onCancel && onCancel()
        })
    }

    const alertDeleteMessage = ({upText, downText}: PropsAlertDelete) => {
        return `<div class="d-flex justify-content-center align-items-center">
                               <div class="w-80 ms-4">
                                    <div class="text-start">
                                        <p class="mb-0 fs-12  ">${upText}</p>
                                    </div>
                                    <div class="text-start mt-1">
                                        <p class="mb-0 fs-12">
                                            <span class="fw-bold text-decoration-underline">Notice:</span> 
                                           ${downText}
                                        </p>
                                    </div>
                                </div>
                        </div>`
    }

    return {
        alertSuccess,
        alertError,
        alertConfirm,
        alertDeleteMessage
    }
}
export {useSweetAlert}