import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialState} from "../../../../../../../_kiloit/layout/core/reducer";
import {ApplicationInfo, InstalledAppModel} from "./Model";

// Define the shape of the state
interface AddProductState {
    products: ApplicationInfo[];
    selectedProduct: number | null;
    installedProduct: InstalledAppModel[];
    loading: boolean;
    appIsFetched: boolean;
}

// Ensure initialState conforms to DashboardState shape
const addProductInitialState: AddProductState = {
    products: [],
    selectedProduct: null,
    ...initialState,
    installedProduct: [],
    loading: false,
    appIsFetched: false
};

export const addProductSlice = createSlice({
    name: "add-product",
    initialState: addProductInitialState,
    reducers: {
        setProducts: (state, action: PayloadAction<ApplicationInfo[]>) => {
            state.products = action.payload;
        },
        setSelectedProduct: (state, action: PayloadAction<number | null>) => {
            state.selectedProduct = action.payload;
        },
        setInstalledProduct: (state, action) =>{
            state.installedProduct = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAppIsFetched: (state, action) => {
            state.appIsFetched = action.payload;
        }
    },
});

export const { setProducts, setSelectedProduct, setInstalledProduct, setLoading, setAppIsFetched} = addProductSlice.actions;

export default addProductSlice.reducer;
