import React, {ReactNode} from "react";
import {
    KiloAttractionLeftMobile,
    KiloAttractionRightMobile,
    KiloFoodLeftMobile,
    KiloFoodRightMobile,
    KiloStayLeftMobile,
    KiloStayRightMobile
} from "../modules/business/components/product-suite/review/core/dummy/DummyMobileContent";

const app: {[key: string]: {name: string; feature: string, mobileContentLeft: ReactNode, mobileContentRight: ReactNode, categoryMax: number}} = {
    1: {
        name: "KiloFOOD",
        feature: "foods",
        mobileContentLeft: <KiloFoodLeftMobile/>,
        mobileContentRight: <KiloFoodRightMobile/>,
        categoryMax: 3
    },
    2: {
        name: "KiloSTAY",
        feature: "Hotel amenitie",
        mobileContentLeft: <KiloStayLeftMobile/>,
        mobileContentRight: <KiloStayRightMobile/>,
        categoryMax: 3
    },
    3: {
        name: "KiloHEALTH",
        feature: "business",
        mobileContentLeft: <KiloAttractionLeftMobile/>,
        mobileContentRight: <KiloAttractionRightMobile/>,
        categoryMax: 3
    },
    4: {
        name: "KiloHR",
        feature: "business",
        mobileContentLeft: <KiloAttractionLeftMobile/>,
        mobileContentRight: <KiloAttractionRightMobile/>,
        categoryMax: 3
    },
    5: {
        name: "KiloATTRACTION",
        feature: "packages and tour itinerary",
        mobileContentLeft: <KiloAttractionLeftMobile/>,
        mobileContentRight: <KiloAttractionRightMobile/>,
        categoryMax: 3
    },
    6: {
        name: "KiloMALL",
        feature: "business",
        mobileContentLeft: <KiloAttractionLeftMobile/>,
        mobileContentRight: <KiloAttractionRightMobile/>,
        categoryMax: 50
    }
}

const validAppIcons: string[] = ["kilo-food.svg", "kilo-stay.svg", "kilo-attraction.svg", "kilo-mall.svg"];

export {app, validAppIcons}