import React, {FC, Fragment, useEffect, useState} from 'react';
import {svgUrl} from 'src/_kiloit/helpers';
import {Col, Image, Row} from 'react-bootstrap';
import moment from 'moment';

type Props = {
    children: React.ReactNode;
}

const MobilePhone: FC<Props> = ({children}) => {
    const [currentTime, setCurrentTime] = useState(moment().format('h:mm'));
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().format('h:mm'));
        }, 30000);
        return () => clearInterval(intervalId);
    }, []);

    const phone = {
        width: 311,
        height: 674
    }

    return (
        <Fragment>
            <div
                style={phone}
                className='position-relative overflow-hidden border border-5 rounded-5 border-dark'
            >
                <div className='position-absolute w-100 z-2'>
                    <Row className="m-0">
                        <Col xs={3} className="d-flex mt-1 fw-bold justify-content-center text-dark">
                            {currentTime}
                        </Col>
                        <Col xs={6} className='d-flex justify-content-center'>
                            <div
                                className='w-100 h-75 rounded-bottom-4 bg-dark'></div>
                        </Col>
                        <Col xs={3} className="mt-1">
                            <div>
                                <Image src={svgUrl('phone-battery-image.svg')} alt="phone info"/>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <div className='bg-white text-dark'>
                        {children}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MobilePhone;
