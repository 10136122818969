import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import InputForm from "../../../../widgets/InputForm";
import * as Yup from "yup";
import {useFormik} from "formik";
import SelectForm from "../../../../widgets/SelectForm";
import CustomCarousel from "../../../../widgets/CustomCarousel";
import {useNavigate} from "react-router-dom";
import {useBusiness} from "../../../core/action";
import StepProgressBar from "../../../../widgets/StepProgressBar";
import CustomBtn from "../../../../widgets/CustomBtn";
import PageTitle from "../../../../widgets/PageTitle";
import "yup-phone-lite";
import {svgUrl} from "../../../../../../_kiloit/helpers";
import {useAuth} from "../../../../auth/core/action";
import {CategoryModel} from "../../../core/model";
import {app} from "../../../../../constants/appFeature";

const validationSchema = Yup.object({
    businessCategories: Yup.array()
        .min(1, "Business Category is required!")
        .max(3, "Only 3 categories are allowed!"),
    contactName: Yup.string().required("Full name is required!"),
    position: Yup.string()
        .min(3, "Invalid position (min 3, max 64)!")
        .max(64, "Invalid position (min 3, max 64)!")
        .required("Position is required!"),
    phoneNumber: Yup.string()
        .phone("KH", "Phone Number is invalid!")
        .matches(/^0[0-9]*$/, "Phone Number must start with 0!")
        .min(9, "Phone Number is invalid!")
        .max(10, "Phone Number is invalid!")
        .required("Phone Number is required!"),
    email: Yup.string().email("Invalid email format!"),
})

const BcAndContacts = () => {
    const navigate = useNavigate();
    const {currentUser} = useAuth();
    const {
        categoryOptions,
        business,
        handleSetBusiness,
    } = useBusiness();
    const [objForEdit] = useState({
        ...business,
        businessName: currentUser?.name,
    });
    const formik = useFormik({
        initialValues: {
            ...objForEdit,
        },
        validationSchema: validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSetBusiness(values);
            navigate("/product-suite/address-and-banner");
        }
    });
    const handleSelect = (e: CategoryModel) => {
        formik.setFieldValue("businessCategories", e);
        handleSetBusiness({businessCategories: e});
    }
    const {appId} = formik.values;
    const validApp = appId && [1, 2, 5, 6].includes(appId) ? appId : 1;

    return (
        <React.Fragment>
            <Row xs={1} lg={2} className="mt-3 justify-content-between align-items-start">
                <Col lg={5} className="order-2 order-lg-1">
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="mt-5 mt-lg-0">
                            <PageTitle title="Business Category"
                                       description="Please select business category and enter contact person information."/>
                            <div className="my-5">
                                <InputForm
                                    className="business"
                                    placeholder=""
                                    required={true}
                                    disabled={true}
                                    type="text"
                                    name="businessName"
                                    label="Business Name"
                                    formik={formik}
                                />
                                <SelectForm
                                    className="business"
                                    placeholder="Select business category"
                                    label="Business Category"
                                    options={categoryOptions}
                                    value={formik.values.businessCategories}
                                    onChange={handleSelect}
                                    required={true}
                                    isDisabled={false}
                                    name="businessCategories"
                                    isMulti={true}
                                    formik={formik}
                                    max={app[validApp].categoryMax}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                />
                            </div>
                        </div>
                        <div>
                            <PageTitle title="Contact Information"/>
                            <div>
                                <InputForm
                                    className="business"
                                    placeholder="Enter full name"
                                    required={true}
                                    disabled={false}
                                    type="text"
                                    name="contactName"
                                    label="Full Name"
                                    formik={formik}
                                />
                                <InputForm
                                    className="business"
                                    placeholder="Enter position"
                                    required={true}
                                    disabled={false}
                                    type="text"
                                    name="position"
                                    label="Position"
                                    formik={formik}
                                />
                                <InputForm
                                    className="business"
                                    placeholder="Enter phone number"
                                    required={true}
                                    disabled={false}
                                    type="tel"
                                    name="phoneNumber"
                                    label="Phone Number"
                                    formik={formik}
                                />
                                <InputForm
                                    className="business"
                                    placeholder="Enter email"
                                    required={false}
                                    disabled={false}
                                    type="email"
                                    name="email"
                                    label="Email"
                                    formik={formik}
                                />
                            </div>
                        </div>
                    </Form>
                    <Row className="g-3 mt-4 mb-4">
                        <Col>
                            <CustomBtn btntype="back" func={() => navigate("/product-suite/add-product")}/>
                        </Col>
                        <Col>
                            <CustomBtn disable={!formik.isValid} title="Next" type="submit" func={formik.handleSubmit}/>
                        </Col>
                    </Row>
                </Col>
                <Col lg={7} className="order-1 order-lg-2">
                    <div className="me-4 me-lg-0">
                        <StepProgressBar/>
                    </div>
                    <div className="ms-auto me-auto me-lg-0 max-width-obj">
                        <CustomCarousel className="h-md rounded shadow" items={Array(3).fill(0).map((_, index) => {
                            return {
                                id: index + 1,
                                src: svgUrl(`${app[validApp].name.toLowerCase()}-slider.svg`),
                            }
                        })}/>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default BcAndContacts