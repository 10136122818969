import React, {FC} from "react";
import {Form} from "react-bootstrap";
import clsx from "clsx";
import Select from "react-select";
import {RequiredField} from "./RequiredField";
import {CustomMultiValue, CustomMultiValueRemove, CustomReactSelect} from "./CustomReactSelect";
import {svgUrl} from "../../../_kiloit/helpers";
import {CategoryModel} from "../business/core/model";

interface Props {
    className?: string;
    value?: any;
    onChange?: any;
    placeholder?: string;
    label?: string;
    options?: any;
    required?: boolean;
    isDisabled?: boolean;
    name: string;
    isMulti: boolean;
    formik: any;
    max?: number;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
}

const SelectForm: FC<Props> = ({
                                   className,
                                   value,
                                   onChange,
                                   placeholder,
                                   label,
                                   options,
                                   required = false,
                                   isDisabled = false,
                                   name,
                                   isMulti,
                                   formik,
                                   max = options.length,
                                   closeMenuOnSelect = true,
                                   hideSelectedOptions = true
                               }) => {

    const formikTouched = formik.touched[name];
    const formikErrors = formik.errors[name];
    const isInValid = formikTouched && formikErrors;
    const getValue = options.find((e: any) => e.value === value) ?? null;

    const handleSelect = (e: CategoryModel) => {
        if (isMulti) {
            const updatedValues = Array.isArray(e) && e.length <= max ? e : value;
            if (onChange) {
                onChange(updatedValues);
            } else {
                formik.setFieldValue(name, updatedValues);
            }
        } else {
            if (onChange) {
                onChange(e);
            } else {
                formik.setFieldValue(name, e.value);
            }
        }
    }

    return (
        <Form.Group controlId={name} className={`mt-3 ${className}`}>
            <Form.Label className={clsx({"required": required})}>{label}</Form.Label>
            <Select
                components={{
                    ClearIndicator: false,
                    IndicatorSeparator: false,
                    DropdownIndicator: CustomReactSelect,
                    MultiValue: CustomMultiValue,
                    MultiValueRemove: CustomMultiValueRemove
                }}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        padding: "2.47px",
                        borderColor: formik.touched[name] && formik.errors[name] ? "#DC3545FF" : "#E9EAEF",
                        "&:hover": {
                            borderColor: formik.touched[name] && formik.errors[name] ? "#DC3545FF" : "#E9EAEF",
                        },
                    }),
                    option: (provided: any, state: any) => ({
                        ...provided,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: state.isSelected ? '#f8f9fa' : 'white',
                        color: "#333333",
                        borderColor: "#9EA2AE",
                        fontWeight: "400",
                        padding: '6px 14px 6px 10px',
                        '&:after': {
                            content: state.isSelected ? `url(${svgUrl("done-select.svg")})` : '""',
                            color: '#9EA2AE',
                            width: "14px",
                            height: "14px",
                            fontWeight: 'bold',
                        },
                        '&:hover': {
                            backgroundColor: state.isSelected ? '#f8f9fa' : '#f8f9fa',
                        },
                    }),
                    multiValueRemove: (baseStyles) => ({
                        ...baseStyles,
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    })
                }}
                className={clsx("form-select p-0 border-0", {"is-invalid is-invalid-select": isInValid})}
                {...formik.getFieldProps(name)}
                placeholder={placeholder}
                options={options}
                value={isMulti ? value : getValue}
                onChange={handleSelect}
                isDisabled={isDisabled}
                name={name}
                isMulti={isMulti}
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={hideSelectedOptions}
            />
            {isInValid && <RequiredField requiredField={formikErrors}/>}
        </Form.Group>
    )
}

export default SelectForm