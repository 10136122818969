import React from "react";
import {useBusiness} from "../../../../../core/action";
import {Button, Col, Image, Row} from "react-bootstrap";
import {svgUrl} from "../../../../../../../../_kiloit/helpers";
import GoogleMaps from "../../../address-and-banner/components/GoogleMaps";
import {kiloAttractionBusiness, kiloFoodBusiness, kiloStayBusiness} from "./dummy";
import clsx from "clsx";
import RightSideMobile from "../../components/mobile/components/RightSideMobile";
import LeftSideMobile from "../../components/mobile/components/LeftSideMobile";
import {useAuth} from "../../../../../../auth/core/action";
import ProductCard from "../../components/mobile/components/ProductCard";

const KiloFoodLeftMobile = () => {
    const {business: {businessName, banners}} = useBusiness();
    const {auth: {user}} = useAuth();
    const {left} = kiloFoodBusiness;

    return <LeftSideMobile isFavourite={false} banners={banners}>
        <div className="p-3 pt-1">
            <div className="d-flex align-items-center gap-2">
                <Image src={user?.logo} alt="profile" className="rounded-circle header-profile-user object-fit-cover"/>
                <h5 className="text-black fs-16 mb-0">{businessName}</h5>
            </div>
            <div className="mt-2 mb-3">
                {left.categories.map((category: string, index: number) => <span key={index}
                                                                                className={clsx("me-3 btn-fs w-100 overflow-hidden text-nowrap", {"text-custom-danger border-bottom border-3 border-custom-danger": index === 0})}>{category}</span>)}
            </div>
            <div>
                <h5 className="text-black fs-16">{left.order.title}</h5>
                <Row xs={2} className="gx-3 gy-0">
                    {Array(6).fill(0).map((_, index) => <ProductCard key={index}/>)}
                </Row>
            </div>
        </div>
    </LeftSideMobile>
}

const KiloStayLeftMobile = () => {
    const {business: {businessName, banners, address}} = useBusiness();
    const {auth: {user}} = useAuth();
    const {left} = kiloStayBusiness;

    return <LeftSideMobile isFavourite={true} banners={banners} isMorePhotos={true}>
        <div className="py-1 px-3 border-bottom border-4">
            <div className="d-flex align-items-center gap-2">
                <Image src={user?.logo} alt="profile" className="rounded-circle header-profile-user object-fit-cover"/>
                <div className="d-block">
                    <h5 className="text-black fs-16 mb-0">{businessName}</h5>
                    <div className="d-flex">
                        {Array(5).fill(0).map((_, index) => <Image key={index} src={svgUrl("rating-star.svg")}/>)}
                    </div>
                </div>
            </div>
            <div className="mt-1 d-flex align-items-center gap-2">
                <Image src={svgUrl("mobile-location.svg")}/>
                <p className="mb-0 custom-text-muted-2">{address}</p>
            </div>
        </div>
        <div className="px-3 py-1 border-bottom border-4">
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-black fs-16 mb-0">{left.amenities.title}</h5>
                    <p className="mb-0 text-primary fs-10">See all</p>
                </div>
                <div className="mt-1 w-100 d-flex justify-content-start gap-2">
                    {left.amenities.categories.map((item, index) => <div key={index}
                                                                         className={clsx("text-center", {"flex-shrink-0": index === 3})}
                                                                         style={{width: 100}}>
                        <Image src={svgUrl(item.icon)}/>
                        <div className="mb-0 text-main">{item.title}</div>
                    </div>)}
                </div>
            </div>
            <div className="my-2">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-black fs-16 mb-0">{left.policies.title}</h5>
                    <p className="mb-0 text-primary fs-10">See all</p>
                </div>
                <p className="mt-1 mb-0">{left.policies.description}</p>
            </div>
            <div>
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-black fs-16 mb-0">{left.descriptions.title}</h5>
                    <p className="mb-0 text-primary fs-10">See all</p>
                </div>
                <p className="mt-1 mb-0">{left.descriptions.description}</p>
            </div>
        </div>
        <div className="px-3 pt-2 pb-3 d-flex flex-wrap align-items-center justify-content-between">
            <p className="mb-0 fs-10 custom-text-muted-2">
                {left.price.description[0]} <br/> <span className="fw-bold text-warning">{left.price.price}</span>
                <br/> {left.price.description[1]}
            </p>
            <Button variant="warning btn-fs rounded-10px" size="sm">Select Room</Button>
        </div>
    </LeftSideMobile>
}

const KiloAttractionLeftMobile = () => {
    const {business: {businessName, banners, address}} = useBusiness();
    const {auth: {user}} = useAuth();
    const {left} = kiloAttractionBusiness;

    return <LeftSideMobile isFavourite={true} banners={banners} isMorePhotos={true}>
        <div className="py-1 px-3 border-bottom border-4">
            <div className="d-flex align-items-center gap-2">
                <Image src={user?.logo} alt="profile" className="rounded-circle header-profile-user object-fit-cover"/>
                <div className="d-block">
                    <h5 className="text-black fs-16 mb-0">{businessName}</h5>
                    <div className="d-flex">
                        {Array(5).fill(0).map((_, index) => <Image key={index} src={svgUrl("rating-star.svg")}/>)}
                    </div>
                </div>
            </div>
            <div className="mt-1 d-flex align-items-center gap-2">
                <Image src={svgUrl("mobile-location.svg")}/>
                <p className="mb-0 custom-text-muted-2">{address}</p>
            </div>
        </div>
        <div className="pt-2 p-3">
            <div className="mb-3">
                {left.categories.map((category: string, index: number) => <span key={index}
                                                                                className={clsx("me-3 btn-fs w-100 overflow-hidden text-nowrap", {"border-bottom border-3 border-main": index === 0})}>{category}</span>)}
            </div>
            <div>
                {left.tours.map((tour, index) => <div key={index} className="mb-3 d-flex gap-3">
                    <Image src={svgUrl(tour.image)} className="mobile-tour-img"/>
                    <span>
                        <h5 className="text-black fs-16 mb-0">{tour.title}</h5>
                        <p className="mb-0 custom-dark-color">{tour.date}</p>
                        <p className="mb-0 custom-dark-color btn-fs mobile-tour-desc">{tour.description}</p>
                        <p className="mb-0 custom-dark-color"><span className="fs-16 fw-bold me-1">{tour.price}</span> per pax</p>
                    </span>
                </div>)}
            </div>
        </div>
    </LeftSideMobile>
}

const KiloFoodRightMobile = () => {
    const {business: {businessName, address, location}} = useBusiness();

    return <RightSideMobile title={businessName}>
        <div>
            <div className="p-3">
                <Row className="align-items-center">
                    <Col xs={1}>
                        <Image src={svgUrl("location_on.svg")}/>
                    </Col>
                    <Col>
                        <h5 className="text-black fs-16 mb-0">{address}</h5>
                    </Col>
                </Row>
            </div>
            <div className="rounded-4 overflow-hidden">
                <GoogleMaps mapTypeControl={false} height="260px" currentLocation={location}/>
            </div>
        </div>
    </RightSideMobile>
}

const KiloStayRightMobile = () => {
    const {business: {businessName}} = useBusiness();

    return <RightSideMobile title={`${businessName}' Hotel`}>
        <div></div>
    </RightSideMobile>
}

const KiloAttractionRightMobile = () => {
    const {right} = kiloAttractionBusiness;

    return <RightSideMobile title={right.title}>
        <div className="w-100">
            <Image src={svgUrl("attraction-banner.svg")} className="w-100"/>
            <div className="py-2 px-3">
                <div>
                    <h5 className="text-black fs-16 mb-0">{right.title}</h5>
                    <p className="mb-0">{right.description}</p></div>
                <div className="my-3">
                    {right.categories.map((category: string, index: number) => <span key={index}
                                                                                     className={clsx("me-3 text-black btn-fs", {"fw-bold border-bottom border-3 border-main": index === 0})}>{category}</span>)}
                </div>
                <div>
                    <h5 className="text-black fs-16 mb-0">{right.travelDate.title}</h5>
                    <div className="w-75 my-2 p-1 rounded-3 border d-flex align-items-center gap-2">
                        <Image src={svgUrl("date.svg")}/>
                        <span className="custom-light-text-muted">Choose travel date</span>
                    </div>
                    <div className="d-flex gap-2 align-items-start">
                        <Image src={svgUrl("check.svg")}/>
                        <div>
                            <p className="mb-0 custom-dark-color">{right.travelDate.subTitle}</p>
                            <p className="mb-0 custom-text-muted-2">{right.travelDate.description}</p>
                        </div>
                    </div>
                </div>
                <div className="my-2">
                    <h5 className="text-black fs-16 mb-0">{right.languageOption.title}</h5>
                    <div
                        className="w-75 my-2 px-3 py-2 rounded-3 border d-flex align-items-center justify-content-between gap-2">
                        <span className="custom-light-text-muted">Choose a language</span>
                        <Image src={svgUrl("dropdown-arrow.svg")}/>
                    </div>
                </div>
                <div className="pt-3">
                    <h5 className="text-black fs-16">{right.ticket.title}</h5>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className="text-black fs-16 mb-0">{right.ticket.subTitle}</h5>
                            <p className="mb-0 custom-dark-color">{right.ticket.price}</p>
                        </div>
                        <div className="d-flex gap-2 justify-content-end align-items-center">
                            <Image src={svgUrl("decrement.svg")}/>
                            <span className="rounded-3 px-3 py-2 border">1</span>
                            <Image src={svgUrl("increment.svg")}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </RightSideMobile>
}

export {
    KiloFoodLeftMobile,
    KiloFoodRightMobile,
    KiloStayLeftMobile,
    KiloStayRightMobile,
    KiloAttractionLeftMobile,
    KiloAttractionRightMobile
}