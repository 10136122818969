import React, {useState} from "react";
import {Col, Dropdown, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {svgUrl} from "src/_kiloit/helpers";
import {useAuth} from "../../../app/modules/auth/core/action";
import {getAuth} from "../../../app/modules/auth";
import {KLSVGIMG} from "../../helpers/components/menu/KLSVGIMG";

const ProfileDropdown = () => {
    const navigate = useNavigate()
    const {logout, auth: {user}} = useAuth();
    const auth = getAuth();
    // Inside your component


    const handleLogout = () => {
        if (auth?.accessToken)
            logout(auth?.accessToken);
        navigate("/");
    };

    const [sections, setSections] = useState<String>("")

    const sectionSwitch = (section: string) => {
        setSections(section)
    };

    const dummy2 = [
        {
            name: "Luna Prime Coffe",
            status: true,
            isInvited: false
        },
        {
            name: "Luna Prime Hotel",
            status: false,
            isInvited: false
        },
        {
            name: "Luna Prime Tour",
            status: false,
            isInvited: false
        }, {
            name: "Luna Prime Coffe",
            status: false,
            isInvited: true
        }, {
            name: "Starbuck AEON2",
            status: false,
            isInvited: true
        }, {
            name: "Tube Coffe",
            status: false,
            isInvited: true
        }, {
            name: "Pizza Company",
            status: false,
            isInvited: true
        }
    ]

    return (
        <React.Fragment>
            <Dropdown autoClose={sections !== "" ? undefined : "outside"}
                      className=" ms-2 header-item topbar-user bg-transparent">
                <span className="d-flex align-items-center">
                    <span className="mt-2">
                        <span className="d-none d-xl-inline-block ms-1 fs-16"
                              style={{color: "#333333", lineHeight: "10px"}}>
                            Hello, {user?.name}
                        </span>
                    </span>
                </span>
                <Dropdown.Toggle
                    type="button"
                    className="btn bg-transparent border-0 arrow-none"
                    id="page-header-user-dropdown dropdown-autoclose-outside">

                    <KLSVGIMG src={user?.logo || svgUrl("profile-placeholder.svg")} className="rounded-circle header-profile-user object-fit-cover"/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end custom-dropdown-menu profile-dropdown">
                    {
                        !sections ? <>
                                <Dropdown.Item onClick={() => sectionSwitch("profile")}>
                                    <Row>
                                        <Col xs={2}><img className="icon-size" src={svgUrl("my-profile.svg")} alt=""/></Col>
                                        <Col xs={10} className="px-3">
                                            <span className="align-middle">My Profile</span>
                                            <p className="custom-text-muted arrow-to-right fw-light fs-12 pb-1">View your
                                                profile</p>
                                        </Col>
                                    </Row>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate("/")}>
                                    <Row>
                                        <Col xs={2}><img className="icon-size" src={svgUrl("setting.svg")} alt=""/></Col>
                                        <Col xs={10} className="px-3">
                                            <span className="align-middle">Settings</span>
                                            <p className="custom-text-muted arrow-to-right fw-light fs-12 pb-1">Update
                                                info,subscription...</p>
                                        </Col>
                                    </Row>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout}>
                                    <Row>
                                        <Col xs={2}><img className="icon-size" src={svgUrl("sign-out.svg")} alt=""/></Col>
                                        <Col xs={10} className="px-3">
                                            <span className="align-middle">Sign out</span>
                                            <p className="custom-text-muted  arrow-to-right fw-light fs-12 pb-1">Exit the
                                                system</p>
                                        </Col>
                                    </Row>
                                </Dropdown.Item>
                            </>
                            :
                            <>
                                {sections === "profile" &&
                                    <>
                                        <div style={{borderBottom: "2px dashed #ccc"}}>
                                            <span onClick={() => sectionSwitch("")}
                                                  className="fw-semibold   cursor-pointer ps-3">
                                                <img width={11} height={11} src={svgUrl("arrow-left.svg")} alt=""/>
                                                <span className="ms-2">Back</span>
                                            </span>
                                            <p className="ms-3 my-2 ">My Profile</p>
                                        </div>

                                        <p style={{color: "#005D7B"}}
                                           className="fs-16 mt-2 fw-bold ms-2 ps-2 mb-1">Switch Business</p>
                                        {
                                            dummy2.map((item, index) => (
                                                <Dropdown.Item onClick={() => sectionSwitch("")}
                                                               className="border-0 pe-1 mb-0" key={index}>
                                                    <span
                                                        className={`${item.status && "fw-bold"}`}>{index + 1}. {" "} {item.name}</span>
                                                    {item.status &&
                                                        <img width={13} src={svgUrl("check-circle.svg")} alt=""/>}
                                                    <span style={{color: "#0BBCD4"}}
                                                          className="fs-12 ms-2">{item.isInvited && "(Invited)"}</span>
                                                </Dropdown.Item>))
                                        }
                                    </>
                                }
                            </>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;